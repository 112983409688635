/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Reservation Bear API
 * The documentation of the REST api of Reservation Bear. A reference implementation is available on [Github](https://github.com/Eistbaren/frontend)
 *
 * OpenAPI spec version: 1.1.0
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from 'url';
import * as isomorphicFetch from 'isomorphic-fetch';
import { Configuration } from './configuration';

export const BASE_PATH = '/api/'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration?: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = isomorphicFetch,
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError';
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

type ModelObject = any;

/**
 * A reservation with all personal data removed
 * @export
 * @interface AnonymousReservation
 */
export interface AnonymousReservation {
  /**
   *
   * @type {Timeslot}
   * @memberof AnonymousReservation
   */
  time?: Timeslot;
  /**
   * ids of the reserved tables of the timeslot
   * @type {Array<string>}
   * @memberof AnonymousReservation
   */
  tables?: Array<string>;
}
/**
 * a comment about a restaurant written by a user
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   *
   * @type {number}
   * @memberof Comment
   */
  rating?: number;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  name?: string;
}
/**
 * a floorplan consisting of a background image and a size
 * @export
 * @interface FloorPlan
 */
export interface FloorPlan {
  /**
   * image of the floor plan of the restaurant without tables
   * @type {string}
   * @memberof FloorPlan
   */
  image?: string;
  /**
   *
   * @type {Size}
   * @memberof FloorPlan
   */
  size?: Size;
}
/**
 * geographic coordinates
 * @export
 * @interface GeographicCoordinates
 */
export interface GeographicCoordinates {
  /**
   *
   * @type {number}
   * @memberof GeographicCoordinates
   */
  lat?: number;
  /**
   *
   * @type {number}
   * @memberof GeographicCoordinates
   */
  lon?: number;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 extends Paginated {
  /**
   *
   * @type {Array<Restaurant>}
   * @memberof InlineResponse200
   */
  results?: Array<Restaurant>;
}
/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 extends Paginated {
  /**
   *
   * @type {Array<Comment>}
   * @memberof InlineResponse2001
   */
  results?: Array<Comment>;
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 extends Paginated {
  /**
   *
   * @type {Array<Table>}
   * @memberof InlineResponse2002
   */
  results?: Array<Table>;
}
/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 extends Paginated {
  /**
   *
   * @type {Array<Timeslot>}
   * @memberof InlineResponse2003
   */
  results?: Array<Timeslot>;
}
/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 extends Paginated {
  /**
   *
   * @type {Array<AnonymousReservation>}
   * @memberof InlineResponse2004
   */
  results?: Array<AnonymousReservation>;
}
/**
 *
 * @export
 * @interface Paginated
 */
export interface Paginated {
  /**
   *
   * @type {number}
   * @memberof Paginated
   */
  totalPages?: number;
  /**
   *
   * @type {number}
   * @memberof Paginated
   */
  currentPage?: number;
  /**
   *
   * @type {number}
   * @memberof Paginated
   */
  pageSize?: number;
  /**
   *
   * @type {Array<ModelObject>}
   * @memberof Paginated
   */
  results?: Array<ModelObject>;
}
/**
 * pixel coordinates on a canvas
 * @export
 * @interface PixelCoordinates
 */
export interface PixelCoordinates {
  /**
   *
   * @type {number}
   * @memberof PixelCoordinates
   */
  x?: number;
  /**
   *
   * @type {number}
   * @memberof PixelCoordinates
   */
  y?: number;
}
/**
 *
 * @export
 * @interface Reservation
 */
export interface Reservation {
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  id?: string;
  /**
   * ids of the reserved tables of the timeslot
   * @type {Array<string>}
   * @memberof Reservation
   */
  tables?: Array<string>;
  /**
   *
   * @type {Timeslot}
   * @memberof Reservation
   */
  time?: Timeslot;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  userName?: string;
  /**
   *
   * @type {string}
   * @memberof Reservation
   */
  userEmail?: string;
  /**
   *
   * @type {boolean}
   * @memberof Reservation
   */
  confirmed?: boolean;
}
/**
 *
 * @export
 * @interface ReservationCreationRequest
 */
export interface ReservationCreationRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ReservationCreationRequest
   */
  tables?: Array<string>;
  /**
   *
   * @type {Timeslot}
   * @memberof ReservationCreationRequest
   */
  time?: Timeslot;
  /**
   *
   * @type {string}
   * @memberof ReservationCreationRequest
   */
  userName?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationCreationRequest
   */
  userEmail?: string;
}
/**
 *
 * @export
 * @interface ReservationIdBody
 */
export interface ReservationIdBody {
  /**
   *
   * @type {boolean}
   * @memberof ReservationIdBody
   */
  confirmed?: boolean;
}
/**
 *
 * @export
 * @interface Restaurant
 */
export interface Restaurant {
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Restaurant
   */
  images?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  website?: string;
  /**
   *
   * @type {Timeslot}
   * @memberof Restaurant
   */
  openingHours?: Timeslot;
  /**
   *
   * @type {number}
   * @memberof Restaurant
   */
  averageRating?: number;
  /**
   *
   * @type {number}
   * @memberof Restaurant
   */
  priceCategory?: number;
  /**
   *
   * @type {GeographicCoordinates}
   * @memberof Restaurant
   */
  location?: GeographicCoordinates;
  /**
   *
   * @type {FloorPlan}
   * @memberof Restaurant
   */
  floorPlan?: FloorPlan;
}
/**
 * size in pixels
 * @export
 * @interface Size
 */
export interface Size {
  /**
   *
   * @type {number}
   * @memberof Size
   */
  width?: number;
  /**
   *
   * @type {number}
   * @memberof Size
   */
  height?: number;
}
/**
 *
 * @export
 * @interface Table
 */
export interface Table {
  /**
   *
   * @type {string}
   * @memberof Table
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Table
   */
  restaurantId?: string;
  /**
   *
   * @type {number}
   * @memberof Table
   */
  seats?: number;
  /**
   *
   * @type {TableOnFloorPlan}
   * @memberof Table
   */
  floorPlan?: TableOnFloorPlan;
}
/**
 * the apperance of a table on the floorpan
 * @export
 * @interface TableOnFloorPlan
 */
export interface TableOnFloorPlan {
  /**
   *
   * @type {PixelCoordinates}
   * @memberof TableOnFloorPlan
   */
  position?: PixelCoordinates;
  /**
   *
   * @type {Size}
   * @memberof TableOnFloorPlan
   */
  size?: Size;
  /**
   *
   * @type {string}
   * @memberof TableOnFloorPlan
   */
  image?: string;
}
/**
 * time range between two unix timestamps
 * @export
 * @interface Timeslot
 */
export interface Timeslot {
  /**
   *
   * @type {number}
   * @memberof Timeslot
   */
  from?: number;
  /**
   *
   * @type {number}
   * @memberof Timeslot
   */
  to?: number;
}
/**
 * ImageApi - fetch parameter creator
 * @export
 */
export const ImageApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary get an image by its id
     * @param {string} id the image id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    imageIdGet(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling imageIdGet.',
        );
      }
      const localVarPath = `/image/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary get an image by its id
     * @param {string} id the image id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    imageIdGet(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Blob> {
      const localVarFetchArgs = ImageApiFetchParamCreator(
        configuration,
      ).imageIdGet(id, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     *
     * @summary get an image by its id
     * @param {string} id the image id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    imageIdGet(id: string, options?: any) {
      return ImageApiFp(configuration).imageIdGet(id, options)(fetch, basePath);
    },
  };
};

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
  /**
   *
   * @summary get an image by its id
   * @param {string} id the image id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImageApi
   */
  public imageIdGet(id: string, options?: any) {
    return ImageApiFp(this.configuration).imageIdGet(id, options)(
      this.fetch,
      this.basePath,
    );
  }
}
/**
 * ReservationApi - fetch parameter creator
 * @export
 */
export const ReservationApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary create a reservation
     * @param {ReservationCreationRequest} body detailed information about the reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReservation(
      body: ReservationCreationRequest,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling createReservation.',
        );
      }
      const localVarPath = `/reservation`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>'ReservationCreationRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary delete a reservation
     * @param {string} id the reservation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReservation(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling deleteReservation.',
        );
      }
      const localVarPath = `/reservation/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: 'DELETE' },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get detailed information about a reservation
     * @param {string} id the reservation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReservation(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getReservation.',
        );
      }
      const localVarPath = `/reservation/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get a reservation ics file
     * @param {string} id the reservation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReservationIcs(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getReservationIcs.',
        );
      }
      const localVarPath = `/reservation/{id}/ics`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary confirm a reservation
     * @param {ReservationIdBody} body
     * @param {string} id the reservation id
     * @param {string} confirmationToken token to confirm the reservation (distributed in the reminder email)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putReservation(
      body: ReservationIdBody,
      id: string,
      confirmationToken: string,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling putReservation.',
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling putReservation.',
        );
      }
      // verify required parameter 'confirmationToken' is not null or undefined
      if (confirmationToken === null || confirmationToken === undefined) {
        throw new RequiredError(
          'confirmationToken',
          'Required parameter confirmationToken was null or undefined when calling putReservation.',
        );
      }
      const localVarPath = `/reservation/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign(
        { method: 'PATCH' },
        options,
      );
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (confirmationToken !== undefined) {
        localVarQueryParameter['confirmationToken'] = confirmationToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );
      const needsSerialization =
        <any>'ReservationIdBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization
        ? JSON.stringify(body || {})
        : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReservationApi - functional programming interface
 * @export
 */
export const ReservationApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary create a reservation
     * @param {ReservationCreationRequest} body detailed information about the reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReservation(
      body: ReservationCreationRequest,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Reservation> {
      const localVarFetchArgs = ReservationApiFetchParamCreator(
        configuration,
      ).createReservation(body, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary delete a reservation
     * @param {string} id the reservation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReservation(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Reservation> {
      const localVarFetchArgs = ReservationApiFetchParamCreator(
        configuration,
      ).deleteReservation(id, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary get detailed information about a reservation
     * @param {string} id the reservation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReservation(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Reservation> {
      const localVarFetchArgs = ReservationApiFetchParamCreator(
        configuration,
      ).getReservation(id, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary get a reservation ics file
     * @param {string} id the reservation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReservationIcs(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Blob> {
      const localVarFetchArgs = ReservationApiFetchParamCreator(
        configuration,
      ).getReservationIcs(id, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary confirm a reservation
     * @param {ReservationIdBody} body
     * @param {string} id the reservation id
     * @param {string} confirmationToken token to confirm the reservation (distributed in the reminder email)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putReservation(
      body: ReservationIdBody,
      id: string,
      confirmationToken: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Reservation> {
      const localVarFetchArgs = ReservationApiFetchParamCreator(
        configuration,
      ).putReservation(body, id, confirmationToken, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ReservationApi - factory interface
 * @export
 */
export const ReservationApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     *
     * @summary create a reservation
     * @param {ReservationCreationRequest} body detailed information about the reservation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReservation(body: ReservationCreationRequest, options?: any) {
      return ReservationApiFp(configuration).createReservation(body, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary delete a reservation
     * @param {string} id the reservation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReservation(id: string, options?: any) {
      return ReservationApiFp(configuration).deleteReservation(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary get detailed information about a reservation
     * @param {string} id the reservation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReservation(id: string, options?: any) {
      return ReservationApiFp(configuration).getReservation(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary get a reservation ics file
     * @param {string} id the reservation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReservationIcs(id: string, options?: any) {
      return ReservationApiFp(configuration).getReservationIcs(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary confirm a reservation
     * @param {ReservationIdBody} body
     * @param {string} id the reservation id
     * @param {string} confirmationToken token to confirm the reservation (distributed in the reminder email)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putReservation(
      body: ReservationIdBody,
      id: string,
      confirmationToken: string,
      options?: any,
    ) {
      return ReservationApiFp(configuration).putReservation(
        body,
        id,
        confirmationToken,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * ReservationApi - object-oriented interface
 * @export
 * @class ReservationApi
 * @extends {BaseAPI}
 */
export class ReservationApi extends BaseAPI {
  /**
   *
   * @summary create a reservation
   * @param {ReservationCreationRequest} body detailed information about the reservation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public createReservation(body: ReservationCreationRequest, options?: any) {
    return ReservationApiFp(this.configuration).createReservation(
      body,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary delete a reservation
   * @param {string} id the reservation id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public deleteReservation(id: string, options?: any) {
    return ReservationApiFp(this.configuration).deleteReservation(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary get detailed information about a reservation
   * @param {string} id the reservation id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public getReservation(id: string, options?: any) {
    return ReservationApiFp(this.configuration).getReservation(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary get a reservation ics file
   * @param {string} id the reservation id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public getReservationIcs(id: string, options?: any) {
    return ReservationApiFp(this.configuration).getReservationIcs(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary confirm a reservation
   * @param {ReservationIdBody} body
   * @param {string} id the reservation id
   * @param {string} confirmationToken token to confirm the reservation (distributed in the reminder email)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationApi
   */
  public putReservation(
    body: ReservationIdBody,
    id: string,
    confirmationToken: string,
    options?: any,
  ) {
    return ReservationApiFp(this.configuration).putReservation(
      body,
      id,
      confirmationToken,
      options,
    )(this.fetch, this.basePath);
  }
}
/**
 * RestaurantApi - fetch parameter creator
 * @export
 */
export const RestaurantApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary get detailed information about a restaurant
     * @param {string} id id of the restaurant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurant(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getRestaurant.',
        );
      }
      const localVarPath = `/restaurant/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get user- comments and reviews of a restaurant
     * @param {string} id id of the restaurant
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantComments(
      id: string,
      currentPage?: number,
      pageSize?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getRestaurantComments.',
        );
      }
      const localVarPath = `/restaurant/{id}/comment`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (currentPage !== undefined) {
        localVarQueryParameter['currentPage'] = currentPage;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get reserved tables of a restaurant
     * @param {string} id id of the restaurant
     * @param {number} from beginning of the first timeslot
     * @param {number} to ending of the last timeslot
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantReservations(
      id: string,
      from: number,
      to: number,
      currentPage?: number,
      pageSize?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getRestaurantReservations.',
        );
      }
      // verify required parameter 'from' is not null or undefined
      if (from === null || from === undefined) {
        throw new RequiredError(
          'from',
          'Required parameter from was null or undefined when calling getRestaurantReservations.',
        );
      }
      // verify required parameter 'to' is not null or undefined
      if (to === null || to === undefined) {
        throw new RequiredError(
          'to',
          'Required parameter to was null or undefined when calling getRestaurantReservations.',
        );
      }
      const localVarPath = `/restaurant/{id}/reservation`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = to;
      }

      if (currentPage !== undefined) {
        localVarQueryParameter['currentPage'] = currentPage;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get all tables and their position in a restaurant
     * @param {string} id id of the restaurant
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantTables(
      id: string,
      currentPage?: number,
      pageSize?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getRestaurantTables.',
        );
      }
      const localVarPath = `/restaurant/{id}/table`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (currentPage !== undefined) {
        localVarQueryParameter['currentPage'] = currentPage;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get reservable timeslots of a restaurant
     * @param {string} id id of the restaurant
     * @param {number} date date of the timeslots
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantTimeslots(
      id: string,
      date: number,
      currentPage?: number,
      pageSize?: number,
      options: any = {},
    ): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getRestaurantTimeslots.',
        );
      }
      // verify required parameter 'date' is not null or undefined
      if (date === null || date === undefined) {
        throw new RequiredError(
          'date',
          'Required parameter date was null or undefined when calling getRestaurantTimeslots.',
        );
      }
      const localVarPath = `/restaurant/{id}/timeslot`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      if (currentPage !== undefined) {
        localVarQueryParameter['currentPage'] = currentPage;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get and filter restaurants
     * @param {Array<string>} [filters] available filters: \\ **query**: *string*, query for seaching restaurants \\ **type**: *string*, type of restaurant e.g. Italian \\ **priceCategory**: *integer (1-3)* \\ **location**: *array[lat, lon]* \\ **radius**: *integer*, radius around the location to search \\ **averageRating**: *integer (1-5)* \\ **timeFrom**: *unix-timestamp* \\ **timeTo**: *unix-timestamp* \\ **numberVisitors**: *integer*
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurants(
      filters?: Array<string>,
      currentPage?: number,
      pageSize?: number,
      options: any = {},
    ): FetchArgs {
      const localVarPath = `/restaurant`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filters) {
        localVarQueryParameter['filters'] = filters.join(
          COLLECTION_FORMATS['csv'],
        );
      }

      if (currentPage !== undefined) {
        localVarQueryParameter['currentPage'] = currentPage;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RestaurantApi - functional programming interface
 * @export
 */
export const RestaurantApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary get detailed information about a restaurant
     * @param {string} id id of the restaurant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurant(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Restaurant> {
      const localVarFetchArgs = RestaurantApiFetchParamCreator(
        configuration,
      ).getRestaurant(id, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary get user- comments and reviews of a restaurant
     * @param {string} id id of the restaurant
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantComments(
      id: string,
      currentPage?: number,
      pageSize?: number,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2001> {
      const localVarFetchArgs = RestaurantApiFetchParamCreator(
        configuration,
      ).getRestaurantComments(id, currentPage, pageSize, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary get reserved tables of a restaurant
     * @param {string} id id of the restaurant
     * @param {number} from beginning of the first timeslot
     * @param {number} to ending of the last timeslot
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantReservations(
      id: string,
      from: number,
      to: number,
      currentPage?: number,
      pageSize?: number,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2004> {
      const localVarFetchArgs = RestaurantApiFetchParamCreator(
        configuration,
      ).getRestaurantReservations(id, from, to, currentPage, pageSize, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary get all tables and their position in a restaurant
     * @param {string} id id of the restaurant
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantTables(
      id: string,
      currentPage?: number,
      pageSize?: number,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2002> {
      const localVarFetchArgs = RestaurantApiFetchParamCreator(
        configuration,
      ).getRestaurantTables(id, currentPage, pageSize, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary get reservable timeslots of a restaurant
     * @param {string} id id of the restaurant
     * @param {number} date date of the timeslots
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantTimeslots(
      id: string,
      date: number,
      currentPage?: number,
      pageSize?: number,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse2003> {
      const localVarFetchArgs = RestaurantApiFetchParamCreator(
        configuration,
      ).getRestaurantTimeslots(id, date, currentPage, pageSize, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary get and filter restaurants
     * @param {Array<string>} [filters] available filters: \\ **query**: *string*, query for seaching restaurants \\ **type**: *string*, type of restaurant e.g. Italian \\ **priceCategory**: *integer (1-3)* \\ **location**: *array[lat, lon]* \\ **radius**: *integer*, radius around the location to search \\ **averageRating**: *integer (1-5)* \\ **timeFrom**: *unix-timestamp* \\ **timeTo**: *unix-timestamp* \\ **numberVisitors**: *integer*
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurants(
      filters?: Array<string>,
      currentPage?: number,
      pageSize?: number,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<InlineResponse200> {
      const localVarFetchArgs = RestaurantApiFetchParamCreator(
        configuration,
      ).getRestaurants(filters, currentPage, pageSize, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * RestaurantApi - factory interface
 * @export
 */
export const RestaurantApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     *
     * @summary get detailed information about a restaurant
     * @param {string} id id of the restaurant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurant(id: string, options?: any) {
      return RestaurantApiFp(configuration).getRestaurant(id, options)(
        fetch,
        basePath,
      );
    },
    /**
     *
     * @summary get user- comments and reviews of a restaurant
     * @param {string} id id of the restaurant
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantComments(
      id: string,
      currentPage?: number,
      pageSize?: number,
      options?: any,
    ) {
      return RestaurantApiFp(configuration).getRestaurantComments(
        id,
        currentPage,
        pageSize,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary get reserved tables of a restaurant
     * @param {string} id id of the restaurant
     * @param {number} from beginning of the first timeslot
     * @param {number} to ending of the last timeslot
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantReservations(
      id: string,
      from: number,
      to: number,
      currentPage?: number,
      pageSize?: number,
      options?: any,
    ) {
      return RestaurantApiFp(configuration).getRestaurantReservations(
        id,
        from,
        to,
        currentPage,
        pageSize,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary get all tables and their position in a restaurant
     * @param {string} id id of the restaurant
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantTables(
      id: string,
      currentPage?: number,
      pageSize?: number,
      options?: any,
    ) {
      return RestaurantApiFp(configuration).getRestaurantTables(
        id,
        currentPage,
        pageSize,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary get reservable timeslots of a restaurant
     * @param {string} id id of the restaurant
     * @param {number} date date of the timeslots
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurantTimeslots(
      id: string,
      date: number,
      currentPage?: number,
      pageSize?: number,
      options?: any,
    ) {
      return RestaurantApiFp(configuration).getRestaurantTimeslots(
        id,
        date,
        currentPage,
        pageSize,
        options,
      )(fetch, basePath);
    },
    /**
     *
     * @summary get and filter restaurants
     * @param {Array<string>} [filters] available filters: \\ **query**: *string*, query for seaching restaurants \\ **type**: *string*, type of restaurant e.g. Italian \\ **priceCategory**: *integer (1-3)* \\ **location**: *array[lat, lon]* \\ **radius**: *integer*, radius around the location to search \\ **averageRating**: *integer (1-5)* \\ **timeFrom**: *unix-timestamp* \\ **timeTo**: *unix-timestamp* \\ **numberVisitors**: *integer*
     * @param {number} [currentPage]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRestaurants(
      filters?: Array<string>,
      currentPage?: number,
      pageSize?: number,
      options?: any,
    ) {
      return RestaurantApiFp(configuration).getRestaurants(
        filters,
        currentPage,
        pageSize,
        options,
      )(fetch, basePath);
    },
  };
};

/**
 * RestaurantApi - object-oriented interface
 * @export
 * @class RestaurantApi
 * @extends {BaseAPI}
 */
export class RestaurantApi extends BaseAPI {
  /**
   *
   * @summary get detailed information about a restaurant
   * @param {string} id id of the restaurant
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestaurantApi
   */
  public getRestaurant(id: string, options?: any) {
    return RestaurantApiFp(this.configuration).getRestaurant(id, options)(
      this.fetch,
      this.basePath,
    );
  }

  /**
   *
   * @summary get user- comments and reviews of a restaurant
   * @param {string} id id of the restaurant
   * @param {number} [currentPage]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestaurantApi
   */
  public getRestaurantComments(
    id: string,
    currentPage?: number,
    pageSize?: number,
    options?: any,
  ) {
    return RestaurantApiFp(this.configuration).getRestaurantComments(
      id,
      currentPage,
      pageSize,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary get reserved tables of a restaurant
   * @param {string} id id of the restaurant
   * @param {number} from beginning of the first timeslot
   * @param {number} to ending of the last timeslot
   * @param {number} [currentPage]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestaurantApi
   */
  public getRestaurantReservations(
    id: string,
    from: number,
    to: number,
    currentPage?: number,
    pageSize?: number,
    options?: any,
  ) {
    return RestaurantApiFp(this.configuration).getRestaurantReservations(
      id,
      from,
      to,
      currentPage,
      pageSize,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary get all tables and their position in a restaurant
   * @param {string} id id of the restaurant
   * @param {number} [currentPage]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestaurantApi
   */
  public getRestaurantTables(
    id: string,
    currentPage?: number,
    pageSize?: number,
    options?: any,
  ) {
    return RestaurantApiFp(this.configuration).getRestaurantTables(
      id,
      currentPage,
      pageSize,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary get reservable timeslots of a restaurant
   * @param {string} id id of the restaurant
   * @param {number} date date of the timeslots
   * @param {number} [currentPage]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestaurantApi
   */
  public getRestaurantTimeslots(
    id: string,
    date: number,
    currentPage?: number,
    pageSize?: number,
    options?: any,
  ) {
    return RestaurantApiFp(this.configuration).getRestaurantTimeslots(
      id,
      date,
      currentPage,
      pageSize,
      options,
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary get and filter restaurants
   * @param {Array<string>} [filters] available filters: \\ **query**: *string*, query for seaching restaurants \\ **type**: *string*, type of restaurant e.g. Italian \\ **priceCategory**: *integer (1-3)* \\ **location**: *array[lat, lon]* \\ **radius**: *integer*, radius around the location to search \\ **averageRating**: *integer (1-5)* \\ **timeFrom**: *unix-timestamp* \\ **timeTo**: *unix-timestamp* \\ **numberVisitors**: *integer*
   * @param {number} [currentPage]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RestaurantApi
   */
  public getRestaurants(
    filters?: Array<string>,
    currentPage?: number,
    pageSize?: number,
    options?: any,
  ) {
    return RestaurantApiFp(this.configuration).getRestaurants(
      filters,
      currentPage,
      pageSize,
      options,
    )(this.fetch, this.basePath);
  }
}
/**
 * TableApi - fetch parameter creator
 * @export
 */
export const TableApiFetchParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary get detailed information about a table
     * @param {string} id detailed informatin about the table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTable(id: string, options: any = {}): FetchArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          'id',
          'Required parameter id was null or undefined when calling getTable.',
        );
      }
      const localVarPath = `/table/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = Object.assign(
        {},
        localVarUrlObj.query,
        localVarQueryParameter,
        options.query,
      );
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign(
        {},
        localVarHeaderParameter,
        options.headers,
      );

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TableApi - functional programming interface
 * @export
 */
export const TableApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary get detailed information about a table
     * @param {string} id detailed informatin about the table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTable(
      id: string,
      options?: any,
    ): (fetch?: FetchAPI, basePath?: string) => Promise<Table> {
      const localVarFetchArgs = TableApiFetchParamCreator(
        configuration,
      ).getTable(id, options);
      return (
        fetch: FetchAPI = isomorphicFetch,
        basePath: string = BASE_PATH,
      ) => {
        return fetch(
          basePath + localVarFetchArgs.url,
          localVarFetchArgs.options,
        ).then(response => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * TableApi - factory interface
 * @export
 */
export const TableApiFactory = function (
  configuration?: Configuration,
  fetch?: FetchAPI,
  basePath?: string,
) {
  return {
    /**
     *
     * @summary get detailed information about a table
     * @param {string} id detailed informatin about the table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTable(id: string, options?: any) {
      return TableApiFp(configuration).getTable(id, options)(fetch, basePath);
    },
  };
};

/**
 * TableApi - object-oriented interface
 * @export
 * @class TableApi
 * @extends {BaseAPI}
 */
export class TableApi extends BaseAPI {
  /**
   *
   * @summary get detailed information about a table
   * @param {string} id detailed informatin about the table
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TableApi
   */
  public getTable(id: string, options?: any) {
    return TableApiFp(this.configuration).getTable(id, options)(
      this.fetch,
      this.basePath,
    );
  }
}
